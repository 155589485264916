<template>
    <article :class="['member', 'member-min', leaderlabels.length>0 ? 'leader': '']" v-if="memberData && memberData.attributes">
        <div class="tile plain">

            <div class="article--intro">
                <div class="article--thumbnail col1">
                    <ImageItem v-if="memberData.field_photo" :imagedata="memberData" 
                        fieldname="field_photo" 
                        :breakstyles="{'0': 'xs_image','576': 'sm_image'}">
                    </ImageItem>
                    <picture v-else>
                        <img src="../../assets/profile.jpg" alt="">
                    </picture>
                </div>

                <div class="col2">
                    <h4 class="article--title">{{ memberData.attributes.field_name }}</h4>
                    <p>{{ memberData.attributes.field_affiliation }}</p>
                </div>
            </div>

            <div class="article--tag blue">
                <div v-for="(l, ind) in this.leaderlabels" :key="'leader'+ind">{{ l }}</div>
            </div>
            
            <div class="article--content">
                
                <div class="article--teaser teaser">
                    <p>{{ memberData.attributes.field_position }}</p>
                </div>

                <div class="article--meta">
                    <span class="label" v-if="memberData.attributes.field_country">{{
                        this.$store.getters.getCountry(memberData.attributes.field_country.country)     
                    }}</span>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import ImageItem from '../atomic-elements/ImageItem'
export default {
    name: 'member-card-min-external',
    components:{
        ImageItem
    },
    props: {
        groupleader: Boolean,
        assistant: Boolean,
        wgcoordinator: Boolean,
        coordinator: Boolean,
        memberData: Object
    },
    computed: {
        leaderlabels() {
            var ll = []
            
            if(this.groupleader)
                ll.push("GROUP LEADER")
            if(this.coordinator)
                ll.push("PROJECT COORDINATOR")
            if(this.assistant) 
                ll.push("WORKING GROUP ASSISTANT")
            if(this.wgcoordinator)
                ll.push("WORKING GROUP COORDINATOR")
            
            if(this.memberData) {
                if(this.memberData.attributes.field_coordinator_type==1)
                    ll.push("QTEdu COORDINATOR")
                else if(this.memberData.attributes.field_coordinator_type==2)
                    ll.push("QTEdu CO-COORDINATOR")
            }

            return ll
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
