<template>
  <div class="page-layout detail-page details">

    <HeaderIntroImage backstep :headerMetadata="pageData"></HeaderIntroImage>

    <div class="container" v-if="!articleData">
      <div class="step">
        <br>
        <h3>Loading Data ...</h3>
        <br>
      </div>
    </div>

    <div v-if="articleData" class="container">
      <div class="step">



        <div class="event-info-box">
          <div class="col1">
            <div class="icon-left calendar"></div>
            <div class="date">
              <p v-html="completeDate"></p>
            </div>
            <p class="teaser">
              from {{ startHours }} 
              <!-- <span class="bold">CET</span>  -->
              to {{ endHours }} 
              <!-- <span class="bold">CET</span></p> -->
            </p>
          </div>

          <div class="col2 location">
            <div class="icon-left location"></div>
            <h4 class="blue"> {{ articleData.attributes.field_event_location_name }}</h4>
            <p class="teaser">{{ articleData.attributes.field_event_location_address }}</p>
          </div>
        </div>

        <div class="content">

          <p v-if="articleData.field_ref_working_group"
            :class="['detail--tag', 'blue', 'icon-left', 'target'+articleData.field_ref_working_group.attributes.drupal_internal__nid]">
            {{ articleData.field_ref_working_group.attributes.title }}
          </p>

          <h1>{{ articleData.attributes.title }}</h1>

          <p v-if="articleData.attributes.field_description" v-html="articleData.attributes.field_description.value">
          </p>

          <ImageItem v-if="articleData.field_image" :imagedata="articleData" :fieldname="'field_image'"
            :breakstyles="{'0': 'sm_image','576': 'md_image' , '1024': 'lg_image'}">
          </ImageItem>

          <h3 v-if="this.agendaDays.length">Agenda</h3>
          <div class="agenda-grid" v-if="this.agendaDays.length">
            <div class="agenda-tile" v-for="(speaker, ind) in this.agendaDays" :key="'speaker'+ind">

              <p class="icon-right read-more date" ref="speaker" v-html="speaker.day" @click="collapseSpeaker(ind)"></p>

              <div ref="speaker-content" class="hidden">
                <div class="speaker-content" v-for="(sp, index) in speaker.speakers" :key="'speaker'+ind+index">
                  <p class="icon-left bullet teaser hours">
                    {{ 'from ' + speakerHour(sp.attributes.field_start_date) }}
                    <!-- <span class="bold">CET</span> -->

                    {{ ' to ' + speakerHour(sp.attributes.field_end_date) }}
                    <!-- <span class="bold">CET</span> -->
                  </p>
                  <h4 class="teaser blue topic">{{ sp.attributes.field_speech_title }}</h4>
                  <p v-if="sp.attributes.field_name" class="speaker">{{ sp.attributes.field_name }}</p>
                  <p v-if="sp.attributes.field_position" class="teaser role">{{ sp.attributes.field_position }}</p>
                  <a v-for="(att, i) in sp.field_slides" :key="'slides'+i+ind" :href="endpoint + att.attributes.uri.url"
                    target="_blank" class="btn download-link slim bold plain">
                    <span class="icon-right download">{{ att.attributes.filename }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="attachments-grid">

            <div v-if="articleData.field_attachment.length" class="box-row">
              <div class="box">
                <p class="label">Attachments</p>
                <a v-for="(att, index) in articleData.field_attachment" :key="'attachment'+index"
                  class="btn download-link slim bold plain"
                  target="_blank"
                  :href="endpoint + att.attributes.uri.url"
                  :download="att.attributes.filename">

                  <span class="download icon-right" v-if="articleData.relationships.field_attachment.data[index].meta.description != '' ">
                    {{articleData.relationships.field_attachment.data[index].meta.description}}
                  </span>

                  <span v-else class="download icon-right">{{ att.attributes.filename }}</span>
                </a>
              </div>
            </div>

            <div class="people-row" v-if="articleData.field_ref_member.length">
              <p class="label">ORGANIZING COMMITTEE</p>
              <ArticlesGrid class="slim" v-if="this.organizingCommiteeMember">
                <MemberCardMin v-for="(m, ind) in this.organizingCommiteeMember" :key="'member'+ind" :memberData="m"
                  :routesData="routesData" />
                <MemberCardMinExternal v-for="(m, ind) in articleData.field_organizing_committee_ext"
                  :key="'member_ext_'+ind" :memberData="m" />
              </ArticlesGrid>
            </div>

          </div>
        </div>
      </div>
      <aside>
        <div class="people-row" v-if="contactPersonsLength">
          <p class="label">CONTACT PERSONS</p>
          <ArticlesGrid class="slim" v-if="articleData.field_ref_member_2">
            <MemberCardMin v-for="(m, ind) in articleData.field_ref_member_2" :key="'contact'+ind" :memberData="m"
              :routesData="routesData" />
            <MemberCardMinExternalEmail v-for="(m, ind) in articleData.attributes.field_contact_person_external"
              :key="'member'+ind" :name="m.first" :email="m.second" />
          </ArticlesGrid>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
  import HeaderIntroImage from '../../components/layout-blocks/HeaderIntroImage.vue'
  import ImageItem from '../../components/atomic-elements/ImageItem.vue'
  import ArticlesGrid from '../../components/layout-blocks/ArticlesGrid.vue'
  import MemberCardMin from '../../components/layout-blocks/MemberCardMin.vue'
  import MemberCardMinExternal from '../../components/layout-blocks/MemberCardMinExternal.vue'
  import MemberCardMinExternalEmail from '../../components/layout-blocks/MemberCardMinExternalEmail.vue'
  import {
    fetchNodes,
    /*fetchRoutes*/
  } from '../../libs/drupalClient'

  export default {
    name: 'event',
    components: {
      HeaderIntroImage,
      ImageItem,
      ArticlesGrid,
      MemberCardMin,
      MemberCardMinExternal,
      MemberCardMinExternalEmail
    },
    props: {},
    data: () => {
      return {
        pageData: {
          attributes: {
            title: "",
            subtitle: "",
            tag: ""
          },

        },
        articleData: null,
        organizingCommiteeMember: []
      }
    },
    computed: {
      lang() {
        return this.$store.state.currentLang
      },
      defaultLang() {
        return this.$store.state.defaultLang
      },
      routesData() {
        return this.$store.state.routesData
      },
      objecttitle() {
        return this.$route.params.title
      },
      objectnid() {
        var link = this.routesData.filter(r => r.url_check == this.objecttitle)

        if (this.routesData.length <= 0) {
          return ''
        } else {
          if (link.length > 0)
            return link[0].nid
          else
            return '/not-found'
        }
      },
      startDate() {
        var year = new Date(this.articleData.attributes.field_start_date).toLocaleDateString("en", { year: 'numeric' })
        var month = new Date(this.articleData.attributes.field_start_date).toLocaleDateString("en", { month: 'short' })
        var day = new Date(this.articleData.attributes.field_start_date).toLocaleDateString("en", { day: 'numeric' })

        return '<span>' + day + ' ' + month + '</span> ' + '<span class="year">' + year + '</span> '
      },
      endDate() {
        var year = new Date(this.articleData.attributes.field_end_date).toLocaleDateString("en", { year: 'numeric' })
        var month = new Date(this.articleData.attributes.field_end_date).toLocaleDateString("en", { month: 'short' })
        var day = new Date(this.articleData.attributes.field_end_date).toLocaleDateString("en", { day: 'numeric' })

        return '<span>' + day + ' ' + month + '</span> ' + '<span class="year">' + year + '</span> '
      },
      completeDate() {
        return '<div class="start-date date">' + this.startDate +
          '</div> <span class="dash">-</span> <div class="end-date date"> ' + this.endDate + '</div>'
      },
      startHours() {  

        return new Date(
          this.manageTimeZones(this.articleData.attributes.field_start_date)
        ).toLocaleTimeString('it-IT', {
          hour: '2-digit',
          hour12: true,
          minute: '2-digit',
          timeZone: 'Europe/Rome',
          timeZoneName: 'short' 
        })

      },
      endHours() {
        return new Date(
          this.manageTimeZones(this.articleData.attributes.field_end_date)
        ).toLocaleTimeString('it-IT', {
          hour: '2-digit',
          hour12: true,
          minute: '2-digit',
          timeZone: 'Europe/Rome',
          timeZoneName: 'short' 
        })
        
      },
      endpoint() {
        return process.env.VUE_APP_ENDPOINT
      },
      agendaDays() {
        var days = []
        if (this.articleData && this.articleData.field_speaker) {
          this.articleData.field_speaker.forEach(ev => {

            let gg = this.speakerDate(ev.attributes.field_start_date)
            if (days.filter(f => f.day == gg).length <= 0)
              days.push({
                'day': gg,
                'speakers': [ev]
              })
            else
              days.find(d => this.speakerDate(d.day == gg)).speakers.push(ev)

          })
        }

        //console.log(days)

        return days
      },
      loggedUser() {
        return this.$store.getters.getLogged
      },
      contactPersonsLength() {
        if(this.loggedUser)
          return this.articleData.field_ref_member_2.length + this.articleData.attributes.field_contact_person_external.length
        else
          return this.articleData.field_ref_member_2.filter(m => 
            (m.attributes.field_member_data_visibility && m.attributes.field_member_data_visibility!='None')).length
            + this.articleData.attributes.field_contact_person_external.length
      }
    },
    methods: {
      manageTimeZones(d){

        //codice per manage timezones 
        let drupalDate = new Date(d)
        //console.log('START drupalDate - ',drupalDate);

        var userTimezoneOffset = drupalDate.getTimezoneOffset();
        //console.log('START userTimezoneOffset - ',  userTimezoneOffset +" -  " + userTimezoneOffset*60000);

        let finalDate =  new Date(drupalDate - (-userTimezoneOffset*60000));
        //console.log('START finalDate - ',finalDate);

        return finalDate

      },
      speakerDate(date) {
        var year = new Date(date).toLocaleDateString("en", {
          year: 'numeric'
        })
        var month = new Date(date).toLocaleDateString("en", {
          month: 'short'
        })
        var day = new Date(date).toLocaleDateString("en", {
          day: 'numeric'
        })

        return '<span>' + day + ' ' + month + '</span> ' + year
      },
      speakerHour(hour) {
        
        return new Date(
          this.manageTimeZones(hour)
        ).toLocaleTimeString('it', {
          hour: '2-digit',
          hour12: true,
          minute: '2-digit',
          timeZone: 'Europe/Rome',
          timeZoneName: 'short' 
        })
        
      },
      async findCT() {
        if (this.objectnid != '' && this.objectnid != '/not-found') {
          await fetchNodes('event', {
              include: ['field_ref_working_group', 'field_image', 'field_attachment',
                'field_organizing_committee_ext', 'field_ref_member', 'field_ref_member_2', 'field_speaker'
              ],
              nested_include: ['field_speaker.field_slides', 'field_ref_member.field_organization',
                'field_ref_member.field_photo',
                'field_ref_member_2.field_organization', 'field_ref_member_2.field_photo'
              ],
              filters: [{
                key: 'drupal_internal__nid',
                value: this.objectnid
              }]
            })
            .then(res => {
              this.articleData = res[0]

              this.organizingCommiteeMember = this.articleData.field_ref_member.sort((a, b) => {
                if (a.attributes.field_last_name < b.attributes.field_last_name) {
                  return -1;
                }
                if (a.attributes.field_last_name > b.attributes.field_last_name) {
                  return 1;
                }
                return 0;
              });

              //this.articleData.field_speaker = this.articleData.field_speaker.filter(s => s.attributes.field_name)
              this.articleData.field_ref_member_ = this.articleData.field_ref_member.filter(m => m.attributes
                .field_member_data_visibility != 'None')
             //console.log('event', this.articleData)
            })

        } else if (this.objectnid == '/not-found')
          this.$router.push(this.objectnid)
      },
      collapseSpeaker(i) {
        this.$refs.speaker[i].classList.toggle('upside');
        this.$refs['speaker-content'][i].classList.toggle('hidden');
      }
    },
    async mounted() {
      await this.findCT()
      //console.log(this.agendaDays)
    },
    watch: {
      async objectnid() {
        await this.findCT()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../styles/variables.scss";

  .event-info-box {

    .col1,
    .col2 {
      padding-top: .5rem;

      p:first-child {
        font-size: 1.5rem;
        line-height: 1.2em;
        margin-bottom: 0;

        ::v-deep span {
          font-weight: 500;
          font-size: 1.75rem;
          line-height: 1.2em;
        }
      }

      p.teaser {
        margin: 1.3rem 0;
      }

    }

    .col2 h4 {
      font-family: $primaryFont;
      font-weight: 500;
      font-size: 1.75rem;
      margin-top: 20px;
    }

    .date {
      ::v-deep p {
        display: flex;
        margin-top: 20px;

        .date {

          span {
            display: block;
          }

          .year {
            color: #A5A9C1;
            font-size: 18px;
          }

        }

        .dash {
          display: block;
          margin: 0 1rem;
        }
      }
    }
  }

  .agenda-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5em;

    /*   margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 3px dotted $primaryColor; */
  }

  .hidden {
    display: none;
  }

  .agenda-tile {
    //padding: 2rem;
    background-color: $lightGray;
    //:hover { cursor: pointer; }

    >p {
      padding: 1.5rem;

      &:before {
        right: 2rem;
      }

      &.upside:before {
        top: 50%;
        transform-origin: unset;
        transform: translateY(-50%) rotateZ(180deg);
      }
    }

    >div {
      padding: 0 1.5rem 2rem 1.5rem;
    }

    .date {
      margin: 0;


      ::v-deep span {
        color: $secondaryColor;
        font-size: 1.25em;
        font-weight: 600;
        font-family: $primaryFont;
      }
    }

    >.date:hover {
      cursor: pointer;
    }

    .speaker-content {
      padding: .5em 0 .5em 0em;
      margin: .5em 0 .5em .5em;
      border-left: 3px dotted darken($hoverGray, 10%);

      h4.teaser {
        font-size: 1.25rem;
      }

      > * {
        padding-left: 2rem;
        margin: .5rem 0 0 0;
      }

      .download-link {
        padding: 0.8em 1em 0.8em 0em;
        margin-left: 2rem;
        margin-top: 1rem;
        background: transparent;

        span {
          color: $primaryColor;
          padding-left: 0;
          margin: 0;
        }

        &:hover {
          background-color: transparent;
          border-bottom: 1px #b8b8b8 solid;

          span {
            color: #2e3da0;
          }
        }
      }
    }
  }

  @media (min-width: 450px) {
    .event-info-box {
      .col1 p:first-child {
        ::v-deep br {
          display: none;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .agenda-tile>p {
      padding: 2rem;
    }

    .agenda-tile .speaker-content {
      padding: 0.5em 0 0.5em 2em;

      h4.teaser {
        font-size: 1.5rem;
      }
    }

    .event-info-box {
      display: flex;
      flex-direction: row;
      //align-items: flex-end;
      justify-content: space-between;

      .col1,
      .col2 {
        width: 48%;
      }

      .col1 p:first-child {

        ::v-deep span {
          font-size: 37px;
          line-height: 1.2em;
        }

        ::v-deep span {
          font-size: 37px;
          line-height: 1.2em;
        }


        ::v-deep br {
          display: block;
        }

      }
    }
  }

  @media (min-width: 1500px) {
    .event-info-box {
      .col1 {
        p:first-child {
          ::v-deep br {
            display: none;
          }
        }
      }
    }
  }
</style>